export default defineI18nConfig(() => ({
  pluralRules: {
    ru: (choice, choicesLength) => {
      const delta = choicesLength === 4 ? 1 : 0;
      if (delta && !choice) return delta;

      choice = Math.abs(choice) % 100;
      const n1 = choice % 10;
      if (choice > 10 && choice < 20) return 2 + delta;
      if (n1 > 1 && n1 < 5) return 1 + delta;
      if (n1 == 1) return delta;
      return 2 + delta;
    },
  },
}));
